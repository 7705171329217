<template>
  <div class="container">
    <van-cell-group inset :border="false">
      <van-field center :border="false" label-align="right" label-width="50" v-model="form.email" label="邮箱" placeholder="请输入邮箱" />
      <van-field center :border="false" label-align="right" label-width="50" v-model="form.code" label="验证码" placeholder="请输入验证码">
        <template #button>
          <van-button size="small" :disabled="sendCodeDisabled" color="var(--app-button-bg-color)" @click="sendCode()">{{ sendCodeBtnText }}</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <van-button block color="var(--app-button-bg-color)" style="margin-top: 15px" @click="updateEmail">{{ subBtnText }}</van-button>
  </div>
</template>
<script>
export default {
  name: 'BindEmail',
}
</script>
<script setup>
import { Form, Field, CellGroup, Button, Toast } from 'vant'
import { ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { bindEmail } from '@/api/player'
import { sendEmailCode } from '@/api/email'
import { redirectPage } from '@/utils/common'
const VanForm = Form
const VanField = Field
const VanCellGroup = CellGroup
const VanButton = Button
const store = useStore()
const router = useRouter()

onMounted(() => {
  initData()
})

const playerExtend = ref({})
const form = reactive({ email: '', code: '', type: 'bindEmail' })
const subBtnText = ref('确认绑定')
const sendCodeBtnText = ref('发送验证码')
const sendCodeDisabled = ref(false)
const countDown = 60
const initData = () => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo').then(res => {
    playerExtend.value = res.data.playerExtend
    form.email = res.data.playerExtend.email
    form.type = playerExtend.value.email ? 'unbindEmail' : 'bindEmail'
    subBtnText.value = playerExtend.value.email ? '确认解绑' : '确认绑定'
  })
}

const setTime = countDown => {
  if (countDown === 0) {
    sendCodeBtnText.value = '发送验证码'
    sendCodeDisabled.value = false
    return
  } else {
    sendCodeBtnText.value = '重新发送(' + countDown + ')'
    countDown--
  }
  setTimeout(() => {
    setTime(countDown)
  }, 1000)
}

const validate = (validateCode = false) => {
  let validated = true
  if (!form.email) {
    Toast('请输入邮箱')
    validated = false
  }

  let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  if (!reg.test(form.email)) {
    Toast('输入的邮箱格式不正确')
    validated = false
  }

  if (validateCode && !this.code) {
    Toast('请输入验证码')
    validated = false
  }

  return validated
}

const sendCode = () => {
  if (!validate()) {
    return false
  }

  sendEmailCode({ toEmail: form.email, type: form.type }).then(res => {
    sendCodeDisabled.value = true
    setTime(countDown)
    Toast.success({ message: res.message })
  })
}

const updateEmail = () => {
  if (!validate()) {
    return false
  }

  bindEmail(form).then(res => {
    store.commit('player/SET_PLAYER_EXTEND', res.data.playerExtend)
    Toast.success({
      message: res.message,
      forbidClick: true,
      onClose: () => {
        redirectPage()
      },
    })
  })
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
}

.send-code {
  width: auto;
  padding: 10px;
  flex-shrink: 0;
  margin: 0;
}
</style>
